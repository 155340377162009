import { Component, OnInit } from '@angular/core';


import { MetodosComponent } from '../services/metodos/metodos.component';


import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';



@Component({
  selector: 'app-login',
  providers:[MetodosComponent ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

@Component({templateUrl: 'login.component.html'})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    message: string="";
    message2: string="";

  constructor( public api:MetodosComponent,private formBuilder: FormBuilder,
        private route: ActivatedRoute
      
        ) {

          this.api. getIpAddress()
        //  this.api.getip2()
              this.message2=""
              this.route.params.subscribe(params=>
                {
                  const mensaje= params['mensaje'];
                  if(mensaje !=null){
            
                  if (mensaje.length >0){
          
                    this.message2=mensaje

                  }
                }
              })


         }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });

  }

  ingresar(){


  }
  ver(msn){

    console.log(msn);
  }


  get f() { return this.loginForm.controls; }
  
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.message=""

   // this.loading = true;
   // this.authenticationService.login(this.f.username.value, this.f.password.value)

   //this.api.redirigir("informacion")



    var user=this.f.username.value
    var pass=this.f.password.value
    this.ver("usernames "+this.f.username.value)
    this.ver("pass es "+this.f.password.value)

   var data  = new FormData();
   data.append("username",this.f.username.value);
   data.append("password",this.f.password.value);
   
   this.api.data_post(this.api.geturlapi()+'/Login/login', data)

   .subscribe((data1: any) => {
 

      if(data1["status"]==true ) {

      this.ver("logueado...")
      
        this.api.saveInLocal('user', user);
        this.api.saveInLocal('token', data1["user"]["token"]);
        this.api.saveInLocal('ses_id', data1["user"]["ses_id"]);
        this.api.saveInLocal('user_id', data1["user"]["user_id"]);
        this.api.saveInLocal('vendedor', data1["user"]["vendedor"]);
    
      //  this.saveInLocal('pass', formObj.pass)
        var object = {value: "value", timestamp: new Date().getTime()}

        this.api.saveInLocal("fecha", JSON.stringify(object));

      //  this.api.seturllocal()
      //this.api.saveInLocal('urlapi', "http://200.30.142.253/apiproducto/index.php");

        //this.ver("urllocal nueva es "+this.api.geturlapi())


        this.api.redirigir("informacion")
      }else{

        this.message="USUARIO Y/O CONTRASEÑA INVALIDO","error"

      }
        
   
    },
   err => {console.log('ocurrio un err en catach ');
 
 

   this.message="OCURRIO UN ERROR AL INGRESAR, VERIFIQUE SUS CREDENCIALES","error"
 }
   
   ); 







        
}

}
