import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InformacionComponent }      from '../app/informacion/informacion.component';
import { LoginComponent }      from '../app/login/login.component';
import { CategoriasComponent }      from '../app/categorias/categorias.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MetodosComponent } from './services/metodos/metodos.component';

const routes: Routes = [
/*   { path: 'categorias', component: CategoriasComponent},
  { path: 'ingresar', component: LoginComponent},
  { path: 'metodos', component: MetodosComponent },

  { path: 'informacion', component: InformacionComponent},
  { path: '', redirectTo: '/informacion', pathMatch: 'full' },
  { path: '**', redirectTo: '/test/facturas/dist/aplication' }
 */

  { path: 'categorias', component: CategoriasComponent},
  { path: 'ingresar', component: LoginComponent},
  { path: 'metodos', component: MetodosComponent },

  { path: 'informacion', component: InformacionComponent},
  { path: '', redirectTo: '/informacion', pathMatch: 'full' },
  { path: '**', redirectTo: '/' }






];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    CKEditorModule
  ],
  exports: [RouterModule,CKEditorModule]
})
export class AppRoutingModule { }














