import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InformacionComponent } from './informacion/informacion.component';

import { RouterModule, Routes } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { MetodosComponent } from './services/metodos/metodos.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap';
import {MaterialModule} from './material.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageServiceModule} from 'angular-webstorage-service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatSortModule } from '@angular/material';
import {MatTableModule} from '@angular/material';
import {MatIconModule} from '@angular/material';
import {MatSelectModule} from '@angular/material';
import {MatMenuModule} from '@angular/material';
import { CategoriasComponent } from './categorias/categorias.component';
import { MatPaginatorModule } from '@angular/material';
import { LoginComponent } from './login/login.component';

import { NgxBarcode6Module } from 'ngx-barcode6';

const appRoutes: Routes = [
  { path: 'informacion', component: InformacionComponent }, 
];


@NgModule({
  declarations: [
    AppComponent,
    InformacionComponent,
    CategoriasComponent,
    LoginComponent,
    MetodosComponent,

  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    AlertModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    StorageServiceModule,
    FormsModule,ReactiveFormsModule,
    MatPaginatorModule,
    RouterModule.forRoot(
      appRoutes // <-- debugging purposes only
    )
    , NgxBarcode6Module
  ],

  // providers: [{provide: APP_BASE_HREF, useValue: '/'},

  //providers: [{provide: APP_BASE_HREF, useValue: '/'},MetodosComponent],
    //providers: [{provide: APP_BASE_HREF, useValue: '/locatnor/productosweb/aplicatio'},MetodosComponent],
//para real
 // providers: [{provide: APP_BASE_HREF, useValue: '/test/facturas/dist/aplication'},MetodosComponent],
 
 providers: [{provide: APP_BASE_HREF, useValue: '/'},MetodosComponent],

  bootstrap: [AppComponent],

})
export class AppModule { }
