import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2/dist/sweetalert2.js'

import { Component, ViewChild ,OnInit, Inject } from '@angular/core';
import swal from'sweetalert2';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { MetodosComponent } from '../services/metodos/metodos.component';


@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
  providers:[MetodosComponent ],
})
export class CategoriasComponent implements OnInit {
  
  constructor(public api:MetodosComponent, private router:Router,private httpClient: HttpClient,  private route: ActivatedRoute,
    ) {

   }


  ngOnInit() {
    



    
  }

}

